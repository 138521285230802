.ready-screen {
    width: 80%;
    max-width: 1080px;
    min-width: 285px;
    margin: 0 auto;
    overflow: hidden;

    &__text {
        max-width: 350px;
        margin: 0 auto;

        @include media('>=768px') {
            margin: 0;
            float: right;
        }
    }

    p {
        font-size: 2.2rem;
    }
    
    .tiny-text{
        text-align: right;
    }
}
