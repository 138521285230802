.bg-cover {
    background-size: cover !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.bg-center {
    background-position: center;
}

.boundary {
    position: absolute;
    overflow-y: hidden;
    width: 100%;
    bottom: 0;
    top: 0;
    pointer-events: none;
}

.sticky {
    position: fixed;
}

.opaque {
    opacity: 1;
}

.flex-1 {
    flex: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.mt-0{
    margin-top: 0;
}

@each $label, $z-index in $z-indexes {
    .z-#{$label} {
        z-index: $z-index !important;
    }
}

@each $label, $color in $colors {
    .border-#{label} {
        border-color: $color !important;
    }

    .bg-#{$label} {
        background-color: $color !important;
    }

    .text-#{$label} {
        color: $color !important;
    }
}

.desktop-only {
    display: none;

    @include media('>=768px') {
        display: flex;
    }
}
