@mixin absolute-cover($width: 100%, $height: 100%) {
    position: absolute;
    height: $height;
    left: 0;
    top: 0;
    width: $width;
}

@mixin placeholder($immediateSelector: false) {
    $pre: '';
    $vendors: ('::-webkit-input-placeholder', ':-moz-placeholder', '::-moz-placeholder', ':-ms-input-placeholder');
    @if ($immediateSelector) {
        $pre: '&';
    }
    @each $vendor in $vendors {
        #{$pre}#{unquote($vendor)} {
            @content;
        }
    }
}

@mixin checkbox {
    input[type='checkbox'] {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it

        & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
            top: 1px;
        }

        // Box.
        & + label:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            background: white;

        }

        // Box hover
        &:hover + label:before {
            background: c(magenta);
        }
        // Box checked
        &:checked + label:before {
            background: c(magenta);
        }

        // Checkmark. Could be replaced with an image
        &:checked + label:after {
            content: '';
            position: absolute;
            left: 5px;
            top: 9px;
            background: white;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
            transform: rotate(45deg);
        }
    }
}
