p {
    font-size: 2rem;
    line-height: 1.25;
}

hr.succinct {
    height: 7px;
    width: 38px;
    background-color: c(magenta);
    border: none;
    margin: 0px;
}

h1 {
    font-size: 5rem;
    line-height: 1.1;
    margin: 10px 0;

    &.huge {
        background-color: none !important;
        font-size: 11rem;
        font-weight: weight(bold);

        @include media('>=768px') {
            font-size: 19rem;
        }
    }
}

.tiny-text {
    display: inline-block;
    text-transform: uppercase;
    color: currentColor;
    font-size: 14px;
    font-weight: weight(demi);
}

.large{
    font-weight: weight(bold);
    font-size: 2.15rem;
    line-height: 1.15;
}

.small{
    font-size: 16px;
}

.details{
    font-size: 14px;
}