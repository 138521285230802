.the-wave {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    pointer-events: none;
    transition: animate(1000);

    &.js-is-hidden {
        opacity: 0;
    }

    &.js-is-active {
        opacity: 1;
    }

    &.reading-mode {
        opacity: 0.3;
    }

    &__container {
        transition: all ease-in-out 500ms;
        width: 100%;
        margin: 0 auto;
        border-radius: 4px;
        position: relative;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        &.feedback-animation {
            transform: scaleY(1.15);
        }

        @include media('>=1170px') {
            top: 50px;
        }

        /*big big screens*/
        @include media('height>=890px', 'width>=1280px') {
            
            top: 15%;
        }

        @include media('<=480px') {
            position: absolute;
            left: 50%;
            transform: scaleY(1.1) translateX(-50%);
            width: 200%;
            top: -55px;

            &.feedback-animation {
                transform: scaleY(1.15) translateX(-50%);
            }
        }
    }

    svg {
        color: c(magenta);
        width: 100%;
        position: relative;

        @include media('height<=768px') {
            top: -50px;
        }

        @include media('<=480px') {
            display: flex;
            align-items: center;
        }
    }
}
