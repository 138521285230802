.thank-you {
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    &__container {
        width: 90%;
        max-width: 1024px;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media('<=480px') {
            margin-top: 50px;
        }

        @include media('>=768px') {
            width: 90%;
            justify-content: space-between;
        }
    }

    &__message {
        flex-basis: 80%;
        display: block;
        max-width: 320px;
        margin-top: 20px;

        @include media('>768px') {
            flex-basis: 50%;
            padding-right: 20px;
        }
    }

    &__header-group {
        display: inline-block;
        text-align: left;
    }

    &__header {
        font-size: 4.5rem;
        display: inline-block;
        margin-bottom: 12px;
    }

    &__email-consent {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        &.js-is-invalid {
            color: c(magenta);
        }

        label {
            width: 40px;
        }

        p {
            flex-basis: 85%;
            font-size: 1.2rem;

            @include media('>768px') {
                font-size: 2.4rem;
            }

            &.small {
                font-size: 1.6rem;
            }
        }
    }

    &__afterthought {
        margin-top: 30px;
    }

    .location-wrapper {
        position: relative;
        display: block;
        overflow: auto;
        max-height: 32vh;
        margin-top: 2em;
        margin-bottom: 1em;
        @include media('<768px') {
            border: 1px solid c(magenta);
            background-color: c(pink-tint);
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &::-webkit-scrollbar-track {
            margin-top: .5ch;
            margin-bottom: .5ch;
            background-color: c(pink-tint);
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: c(magenta);
            -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
        }
    }
    
    .location-list {
        padding: .5ch 2ch 3ch 0px;
        @include media('<768px') {
            padding-left: .5ch;
        }
        display: flex;
        gap: .5ch;
        flex-direction: column;
        a {
            display: inline;
            font-weight: bold;
            color: c(magenta);
            text-decoration: underline;
        }
    }

    [data-js='copy-text'] {
        display: block;
        margin-top: 10px;
        flex-flow: row nowrap;
        width: 100%;
        padding: 0 20px 0 10px;
        height: 42px;
        transition: animate(300);
        opacity: 1;
    }

    .intro__big-hello {
        left: 71px;
        top: 20px;

        @include media('<=480px') {
            display: none;
            position: absolute;
            top: 0px;
            right: 0;
            left: unset;
        }
    }

    .tiny-text {
        display: block;
        font-weight: weight(bold);
    }
}

.go-again {
    overflow: hidden;
    width: 200px;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;

    @include media('<=480px') {
        display: none;
    }

    svg {
        width: 18px;
        margin: 0 auto;
    }
    p {
        margin-top: 10px;
    }
}
