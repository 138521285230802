strong {
    font-weight: weight(bold);
}

img {
    display: block;
    max-width: 100%;
}

a {
    color: currentColor;
    display: inline-block;
    text-decoration: none;
    transition: all 300ms ease;

    &:before,
    &:after {
        transition: inherit;
    }

    &:hover {
        text-decoration: none;
    }
}

button {
    background: none;
    border: none;
    color: currentColor;
    cursor: pointer;
    display: inline-block;
    font-size: inherit;
    outline: none;
    padding: 0;
    text-transform: inherit;

    &:before,
    &:after {
        transition: inherit;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.75;
    }
}

address {
    font-style: normal;
}

form {
    max-width: 100%;
}

input {
    appearance: none;
    border: none;
    border-radius: 0;
    color: currentColor;
    outline: none;
    max-width: 100%;
}

input[type='submit'] {
    cursor: pointer;
}

select {
    appearance: none;
    border: none;
    overflow: hidden;
    outline: none;
    user-select: none;
    text-shadow: none;
    white-space: nowrap;
}

ul,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    display: inline-block;
}

svg {
    display: block;
    max-width: 100%;
}

.lit-html-root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media screen and(max-width: 480px) {
        position: fixed;
    }
}

[data-js='nickname-text'] {
    text-transform: capitalize;
}

#fp-nav ul li a span {
    background-color: c(magenta);

    &.disabled {
        background-color: #666666;
    }
}

.text-mobile-only {
    display: none;

    @include media('<=480px') {
        display: inline;
    }
}

//shrink up pesky pararaph margins on mobile
.intro__text,
.info-section__reading-bounds {
    p {
        @include media('<=480px') {
            margin-top: 22px;
            margin-bottom: 0px;

            & + p {
                margin-top: 15px;
            }
        }
    }
}

.opacity-override {
    opacity: 0 !important;
}
