/*———— -https://codepen.io/songyima/pen/VKbyYG- ————*/
/*/////////////////////////////*/

.fullscreen-video-background {
    position: absolute;
    top: 0;
    bottom: 0;
    background: transparent;
    width: 100%;
    z-index: -1;
    overflow: hidden;
    opacity: 1;
    transition: animate(500);
    pointer-events: none;
    opacity: 0;

    #_youtube-iframe-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: absolute;
        height: 100%;

        #_youtube-iframe {
            position: absolute;
            pointer-events: none;
            margin: 0 auto;
            left: 50%;
            transform: translateX(-50%);
            ////
            height: 280vh;
            width: 112vw;
            @include media('height>=800px') {
                height: 287.5vh;
                width: 115vw;
            }

            @include media('<=480px') {
                display: none;
            }
        }
    }

    .fullscreen-gif-background{
        display: none;
        
        @include media('<=480px') {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: 0 auto;

            [data-js="orgasm-gif"]{
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%) scale(1.2);   
            }
        }
    }

    &.js-is-active {
        z-index: 50;
        opacity: 1;
    }
}
