@import 'tools/functions/map_sugar';

/**
 * bootstrap override; required for other variables
 * remaining overrides at bottom of this file
*/

/*———— -Directories- ————*/
$theme-directory: '/wp-content/themes/harkinsbuilders';
$directories: (
    theme: $theme-directory,
    assets: '#{$theme-directory}/assets',
    images: '#{$theme-directory}/images',
    svg: '#{$theme-directory}/svg',
);

/*———— -Typefaces etc- ————*/
$neutra: 'Neutraface Text', 'Proxima Nova', 'Helvetica', 'sans-serif';

$weights: (
    regular: 400,
    demi: 500,
    bold: 700,
);

$rem-base: 10px;

/*———— -Z-indices- ————*/
$z-indexes: (
    sky: 100,
    mountain: 50,
    hill: 10,
    land: 5,
    default: 1,
    sea: -1,
);

/*———— -Colors- ————*/
$colors: (
    white: #fff,
    black: #231f20,
    magenta: #d41367,
    circle: #d51668,
    magenta-trans: rgba(213, 22, 104, 0.9),
    pink: #efbdbd,
    pink-tint: #f2caca,
);

/*———— -Animación- ————*/
$animation-curve: cubic-bezier(0.21, 0.74, 0.67, 0.99);

$animations: (
    300: all $animation-curve 300ms,
    400: all $animation-curve 400ms,
    500: all $animation-curve 500ms,
    600: all $animation-curve 600ms,
    750: all $animation-curve 750ms,
    1000: all $animation-curve 100ms,
    1500: all $animation-curve 1500ms,
);
