html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: $rem-base;

    @include media('<480px') {
        font-size: 9px;
    }
    @include media('height>=1024px', 'width>1550px') {
        //font-size: 11px;
    }
}

body {
    background-color: c(pink);
    color: c(black);
    font: {
        family: $neutra;
        size: 18px;
        weight: weight(regular);
    }
    line-height: 1.4;
    margin: 0;
    text-align: left;
}

html,
body {
    max-width: 100%;

    &.ios {
        height: 3500px !important;
    }

    &.ios-scrollbar-hidden {
        height: 100vh !important;
        overflow: hidden;
    }
}

.skip-link {
    display: none;
}
