.intro {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    &__container {
        position: relative;
        display: flex;
        overflow: hidden;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;
        margin: 0 auto;
        width: 90%;
        height: 100vh;
        overflow: hidden;

        @include media('<=768px') {
            margin-top: 90px;
            height: calc(100vh - 110px);
        }

        @include media('<=480px') {
            height: auto;
            position: absolute;
            top: 90px;
            bottom: 0;
            left: 0;
            right: 0;
            margin-top: 0px;
            justify-content: flex-start;
        }

        @include media('>=992px') {
            align-items: center;
            justify-content: space-between;
            flex-flow: row nowrap;
            width: 100%;
            max-width: 1170px;
        }
    }

    &__big-hello {
        transition: left ease-in-out 800ms, transform ease-in-out 800ms, opacity ease 300ms;
        transform: translateX(-2%);
        transform: translateY(0%);

        @include media('<=768px') {
            h1 {
                transition: all ease 300ms;
            }
            .fly-away {
                opacity: 0;
            }
        }

        @include media('>=992px') {
            position: relative;
            left: 105px;
            top: 15px;
        }
    }

    &__text {
        width: 260px;
        overflow: hidden;
        transition: all ease-in-out 800ms;
        transform: translateX(0);

        @include media('<=480px') {
            width: 100%;
            padding: 0 15px;
            order: 2;
            max-width: 315px;
        }

        @include media('>=992px') {
            margin-left: 165px;
        }

        &-header {
            @include media('<=480px') {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                max-width: 285px;
                margin-bottom: 20px;
                h1 {
                    position: relative;
                    top: 25px;
                    order: 2;
                }
                .intro__product-image {
                    order: 1;
                    margin: 0px !important;
                }
            }
        }

        h1 {
            font-size: 4.5rem;
        }
    }

    &__age-gate {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25%;
        width: 90%;
        max-width: 450px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: animate(400);
        padding-left: 32px;
        padding-right: 32px;
        text-align: center;
        &.js-is-active {
            opacity: 1;
        }

        @include media('height<=768px') {
            // bottom: 50%;
        }

        @include media('>768px') {
            bottom: 100px;
        }

        @include media('height<735px') {
            bottom: 35px;
        }

        @include media('<=480px') {
            max-width: 340px;
            width: 100%;
            bottom: 80px;
        }

        @include checkbox;

        label {
            margin-bottom: 8px;
            font-weight: bold;
        }
        .warning-msg {
            display: none;
            opacity: 0;
            transition: opacity 0.5s ease-in-out; 
        }
        .age-gate-form__container {
            display: flex;
            margin-top: 20px;
            flex-flow: row nowrap;
            color: c(magenta);

            @include media('<=480px') {
                transition: animate(500);

                &.stay-visible{
                    position: absolute;
                    top: 0;
                }
            }
        }
        .age-field {
            width: 100%;
            font-size: 1.3rem;
            font-weight: weight(bold);
            line-height: 1;
            vertical-align: baseline;
            padding: 20px 20px 18px;
    
            @include media('<=480px') {
                font-size: 1.7rem;
            }
    
            @include placeholder(true) {
                color: c(magenta);
                opacity: 0.8;
            }
    
            @include media('>768px') {
                font-size: 2.4rem;
            }
        }
        .cta-btn {
            padding-left: 22px;
            padding-right: 22px;
        }
    }

    .intro__product-image {
        height: 400px;
        width: auto;
        opacity: 1;
        transition: all ease-in-out 800ms;
        transform: translateX(0);

        @include media('>=768px') {
            padding-right: 20px;
        }

        @include media('<=480px') {
            margin: 0 auto;
            height: 190px;
            order: 1;
        }

        @include media('<=375px') {
            height: 150px;
            position: relative;
            left: -4px;
        }

        &.wiggle {
            @include wiggle;
            animation: wiggle 1000ms;
        }
    }

    /*———— -load in animation- ————*/
    @include fadeIn;

    &.loading {
        .intro__text {
            opacity: 0;
            transform: translateX(200%);
        }

        .intro__product-image {
            opacity: 0;
            transform: translateX(100%);
        }

        .intro__big-hello {
            opacity: 0;
            animation: fadeIn 750ms;
            animation-delay: 1000ms;
            animation-fill-mode: forwards;
            left: 50%;
            transform: translate(0, 100%);

            @include media('<=768px') {
                width: auto;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                h1 {
                    position: relative;
                    left: 15px;
                    top: -33px;
                    font-size: 110px;
                }
            }

            @include media('>992px') {
                display: block;
                transform: translate(-40%, 0);
            }
        }
    }
}
