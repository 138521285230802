@mixin fadeIn {
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

@mixin wiggle {
    @keyframes wiggle {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(0deg);
        }
        65% {
            transform: rotate(-7.5deg);
        }
        80% {
            transform: rotate(7deg);
        }
        90% {
            transform: rotate(-3.5deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
}

//floating arrow animation
@mixin float {
    @keyframes float {
        0% {
            transform: translatey(0px);
        }
        50% {
            transform: translatey(-10px);
        }
        100% {
            transform: translatey(0px);
        }
    }
}

@mixin pulse {
    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes pulse-large {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.3);
        }
        100% {
            transform: scale(1);
        }
    }
}

@mixin jello {
    @keyframes jello {
        from,
        11.1%,
        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        22.2% {
            -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
            transform: skewX(-12.5deg) skewY(-12.5deg);
        }

        33.3% {
            -webkit-transform: skewX(6.25deg) skewY(6.25deg);
            transform: skewX(6.25deg) skewY(6.25deg);
        }

        44.4% {
            -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
            transform: skewX(-3.125deg) skewY(-3.125deg);
        }

        55.5% {
            -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
            transform: skewX(1.5625deg) skewY(1.5625deg);
        }

        66.6% {
            -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
            transform: skewX(-0.78125deg) skewY(-0.78125deg);
        }

        77.7% {
            -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
            transform: skewX(0.390625deg) skewY(0.390625deg);
        }

        88.8% {
            -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
            transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        }
    }
}

@mixin shake {
    @keyframes shake {
        from,
        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        10%,
        30%,
        50%,
        70%,
        90% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }

        20%,
        40%,
        60%,
        80% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }
    }
}

@mixin rotating {
    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes rotating-reverse {
        from {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
        to {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }
}

@mixin rotatingGrow {
    @keyframes rotating-grow {
        from {
            -ms-transform: rotate(0deg) scale(0);
            -moz-transform: rotate(0deg) scale(0);
            -webkit-transform: rotate(0deg) scale(0);
            -o-transform: rotate(0deg) scale(0);
            transform: rotate(0deg) scale(0);
            opacity: 0;
        }
        to {
            -ms-transform: rotate(60deg) scale(6);
            -moz-transform: rotate(60deg) scale(6);
            -webkit-transform: rotate(60deg) scale(6);
            -o-transform: rotate(60deg) scale(6);
            transform: rotate(60deg) scale(6);
            opacity: 1;
        }
    }

    @keyframes rotating-grow-reverse {
        from {
            -ms-transform: rotate(60deg) scale(0);
            -moz-transform: rotate(60deg) scale(0);
            -webkit-transform: rotate(60deg) scale(0);
            -o-transform: rotate(60deg) scale(0);
            transform: rotate(60deg) scale(0);
            opacity: 0;
        }
        to {
            -ms-transform: rotate(0deg) scale(6);
            -moz-transform: rotate(0deg) scale(6);
            -webkit-transform: rotate(0deg) scale(6);
            -o-transform: rotate(0deg) scale(6);
            transform: rotate(0deg) scale(6);
            opacity: 1;
        }
    }
}

@mixin fadeOutUp {
    @keyframes fadeOutUp {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.8;
        }

        100% {
            -webkit-transform: translate3d(0, -150%, 0) scale(2.3);
            transform: translate3d(0, -150%, 0) scale(2.3);
        }
    }
}

.oh-oh-oh {
    opacity: 0;
}

/*———— -Animation utility classes- ————*/
.fade {
    opacity: 0;

    &.fade-in {
        opacity: 1 !important;
    }

    &.fade-out {
        opacity: 0 !important;
    }
}

.animated {
    -webkit-transform-style: preserve-3d;
    &.reveal {
        opacity: 0;
        transition: animate(400);

        &.fade-in {
            opacity: 1;
        }

        &.fade-out {
            opacity: 0;
        }
    }

    &.pulse {
        @include pulse;

        &.pulse-now {
            animation: pulse 500ms;
        }

        &.pulse-now-large {
            animation: pulse-large 500ms;
        }
    }

    &.jello {
        @include jello;
        animation: jello 800ms;
    }

    &.shake {
        @include shake;
        animation: jello 500ms;
    }

    &.oh-oh-oh {
        transform-origin: center center;
        @include rotatingGrow;
        animation: rotating-grow 4.5s ease-in;

        &.reverse {
            animation: rotating-grow-reverse 4.5s ease-in;
        }
    }
    &.rotator {
        transform-origin: center center;
        @include rotating;
        animation: rotating 6s linear infinite;

        &.reverse {
            animation: rotating-reverse 3s linear infinite;

            &.pulse-now-large {
                animation: rotating-reverse 3s linear infinite, pulse-large 500ms;
            }
        }

        &.pulse-now-large {
            animation: rotating 6s linear infinite, pulse-large 500ms;
        }
    }

    &.fade-up {
        @include fadeOutUp;
        animation: fadeOutUp 800ms;
    }

    &.fade-up-long {
        @include fadeOutUp;
        animation: fadeOutUp 2250ms;
    }
}
