.info-section {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    transition: animate(400);

    &__reading-bounds {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 1440px;
        transition: animate(600);
    }

    &__container {
        position: absolute;
        transition: animate(600);
        width: 100%;
        max-width: 1024px;
        bottom: 30px;

        @include media('height<=768px') {
            bottom: 25px;
        }

        @include media('>768px') {
            width: 90%;
        }
    }

    &__afterthought {
        transition: animate(500);
    }

    &__text {
        transition: animate(500);
        h1 {
            font-size: 4.5rem;
        }

        &.ingredient-reveal {
            @include media('>=768px') {
                position: relative;
                left: 200px;
            }
        }
    }

    &__back-arrow {
        color: c(magenta);
        width: 20px;
        height: 20px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        flex-flow: row nowrap;
        text-decoration: underline;
        font-size: 2rem;

        &:hover {
            color: c(black);
        }

        svg {
            display: inline-block;
            margin-right: 12px;
            height: 20px;
            width: 20px;
        }
        span {
            text-transform: capitalize;
        }
    }

    &__gif {
        height: 200px;
        width: 200px;
        overflow: hidden;
        position: relative;

        img {
            opacity: 0.9;
            mix-blend-mode: multiply;
            filter: brightness(1.01);
            width: 250px;
            height: 250px;
        }

        img.contained {
            display: block;
            width: 250px;
            height: 250px;
            max-width: unset;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__image {
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 90%;
        max-width: 400px;

        img {
            transform: translateX(0) translateY(0);
            transition: transform cubic-bezier(0.175, 0.885, 0.32, 1.275) 800ms;
            -webkit-transform-style: preserve-3d;
        }
    }

    &__image-circle {
        position: absolute;
        transition: transform cubic-bezier(0.175, 0.885, 0.32, 1.275) 800ms;
        -webkit-transform-style: preserve-3d;
        transform: translateX(0) translateY(0);
        background-image: url('images/circle.png');
        background-size: cover;
        opacity: 0.9;
        mix-blend-mode: multiply;
        width: 220px;
        height: 220px;
        border-radius: 100%;

        @include media('>=786px') {
            width: 450px;
            height: 450px;
        }
    }

    &.info-section-1,
    &.info-section-3 {
        .info-section__container {
            max-width: 810px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: center;

            @include media('>768px') {
                right: 80px;
                flex-flow: row nowrap;
            }

            @include media('>=1024px') {
                right: 150px;
            }
        }

        .info-section__image-circle {
            left: -200px;
            top: 100px;
        }

        .info-section__text {
            width: 300px;
        }

        .info-section__afterthought {
            width: 300px;

            @include media('>768px') {
                width: 230px;
            }

            p {
                font-weight: weight(bold);
                color: c(magenta);
                font-size: 2.8rem;
            }
        }
    }

    &.info-section-2 {
        .info-section__container {
            left: 0;
            height: 100vh;
            max-width: 700px;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;

            @include media('>768px') {
                padding: 0;
                left: 80px;
            }
        }

        .info-section__image {
            right: 0;
        }

        .info-section__gif {
            width: 100%;
            height: 300px;
            position: relative;
            top: 30px;

            img {
                height: 150px;
                width: 150px;

                @include media('>768px') {
                    height: 250px;
                    width: 250px;
                }
            }

            h1 {
                margin-bottom: 50px;
            }

            .image-content-2-up {
                height: 250px;
                width: 100%;
                display: flex;
                align-items: flex-end;
                flex-flow: column nowrap;
                justify-content: space-between;

                @include media('<768px') {
                    align-items: center;
                }

                @include media('>768px') {
                    width: 700px;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    h1 {
                        margin-bottom: 10px;
                    }
                }
            }

            
        }

        .info-section__image-circle {
            height: 500px;
            width: 500px;
            right: -55%;
            top: -10%;
        }

        .info-section__text {
            opacity: 0;
            width: 300px;
        }

        .info-section__afterthought {
            opacity: 0;
            position: relative;
            top: -40px;

            @include media('>768px') {
                top: -10px;
            }

            p {
                margin: 0;
            }
            p.large {
                width: 230px;
                margin-top: 10px;
            }
        }
    }

    &.info-section-3 {
        .info-section__image-circle {
            left: -24%;
            top: -11%;
        }

        .info-section__text {
            width: 285px;
        }

        .info-section__afterthought {
            width: 300px;

            @include media('>768px') {
                width: 260px;
            }

            p {
                font-size: 2.7rem;
            }
        }
    }

    &.info-section-4 {
        .info-section__container {
            margin: 0 auto;
            max-width: 800px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: center;

            @include media('>768px') {
                flex-flow: row nowrap;
                left: -120px;
                right: 0;
            }
        }

        .info-section__image {
            right: 0;
        }

        .info-section__image-circle {
            height: 500px;
            width: 500px;
            right: -40%;
            top: -10%;
        }

        .info-section__gif {
            @include media('>=768px') {
                width: 135px;
                height: 135px;
            }
            @include media('>=1170px') {
                position: relative;
                top: -70px;
            }
        }

        .info-section__text {
            width: 280px;
        }

        .info-section__afterthought {
            position: relative;
            top: -10px;

            p {
                margin: 0;
            }
            p.large {
                width: 240px;
                margin-top: 10px;
            }
        }
    }

    .reveal {
        opacity: 0;
        transition: animate(500);
        -webkit-transform-style: preserve-3d;

        &.fade-in {
            opacity: 1;
        }

        &.fade-out {
            opacity: 0;
        }
    }

    .fade-out {
        opacity: 0;
    }
}

/* smaller-width-override-styles */
.fp-section .info-section .info-section__image {
    transition: animate(400);

    @include media('<=1440px') {
        max-width: 350px;

        &-circle {
            width: 350px;
            height: 350px;
        }
    }
}

.fp-section .info-section .info-section__image {
    @include media('<=1330px') {
        max-width: 285px;

        &-circle {
            width: 225px;
            height: 225px;
        }
    }
}

/*  mobile-styles */
@include media('<=480px') {
    //just for specificity...
    .fp-section {
        .info-section__scroller {
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;

            [data-js='check-finished'] {
                height: 10px;
                width: 100%;
                position: relative;
                top: -10px;
            }
        }

        .info-section {
            position: relative;
            overflow: hidden;
            padding-bottom: 90px;

            &:nth-of-type(2) {
                padding-bottom: calc(100vh - 80px);
            }

            &:nth-of-type(even) {
                .info-section__image {
                    right: unset;
                    float: left;
                    //transform: scaleX(-1);
                }

                .info-section__gif {
                    position: relative;
                    left: calc(100% - 150px);
                }

                .info-section__container {
                    padding: 0;
                }
            }

            .info-section__reading-bounds {
                position: relative;
                z-index: 10;
                overflow: hidden;
            }

            .info-section__container {
                position: relative;
                display: block !important;
                width: 85%;
                min-width: 285px;
                top: unset;
                bottom: unset;
                left: unset;
                right: unset;
                height: auto;
                margin: 0 auto;
            }

            .info-section__text {
                margin-top: 100px;
                transition: animate(500);
            }

            .info-section__afterthought {
            }

            .info-section__image {
                width: 45%;
                transform: none;
                top: 0;
                right: 0;

                .info-section__image-circle {
                    width: 140px;
                    height: 140px;
                    left: -20px;
                    top: 20px;
                    bottom: -20px;
                }
            }

            .info-section__gif {
                margin-top: 80px;
                height: 220px;
                width: 160px;
                position: relative;
                z-index: -1;
                left: -25px;
                top: 0;

                img {
                    height: 160px;
                    width: auto;
                }
            }
        }

        .info-section.info-section-2 {
            .info-section__text {
                margin-top: 60px;
            }

            .info-section__afterthought {
                top: 0;
                margin-top: 20px;

                &-dropzone {
                    margin-top: 30px;

                    .succinct {
                        display: none;
                    }

                    p.large {
                        width: 100%;
                    }
                }
            }
        }
    }
}

//Huge screen styles

.info-section:not(.info-section-2) {
    .info-section__reading-bounds {
        @include media('height>=890px', 'width>=1280px') {
            height: 730px;
            transform: translateY(-50%);
            top: 50%;
        }
    }
}

@include media('height>=890px', 'width>=1280px') {
    .info-section.info-section-2{
        .info-section__reading-bounds {
                height: 730px;
                transform: translateY(-50%);
                top: 50%;
        }

        .info-section__container{
            height: 730px;
        }

    }
}

//iOS fix
.ios-fix-hidden-text{
    display: none;
    
    @include media ('<=480px'){
        opacity: 0;
        display: block;
        height: 55vh;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}