.nickname-form {
    position: relative;
    margin: 0 auto;
    width: 90%;
    max-width: 650px;
    text-align: center;
    padding-bottom: 35px;

    @include media('<=480px') {
        top: -30px;
    }

    &__header-group {
        transition: animate(400);
        overflow: hidden;
        display: inline-block;
        text-align: left;
        max-height: 500px;
    }

    &__header {
        font-size: 5rem;
        display: inline-block;
        margin-bottom: 12px;
    }

    &__input {
        display: flex;
        margin-top: 30px;
        flex-flow: row nowrap;
        color: c(magenta);
    }

    [data-js='nickname-input'] {
        width: 100%;
        font-size: 2.4rem;
        font-weight: weight(bold);
        line-height: 1;
        vertical-align: baseline;
        padding: 20px 20px 18px;

        @include placeholder(true) {
            color: c(magenta);
            opacity: 0.3;
        }
    }

    [data-js='nickname-submit'] {
        padding-left: 22px;
        padding-right: 22px;
    }

    .tiny-text {
        position: absolute;
        bottom: 0;
        right: -5px;

        @include media('<=480px') {
            right: unset;
            left: 50%;
            transform: translate(-50%);
        }
    }
}
