.orgasm {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    &__container {
        position: absolute;
        width: 90%;
        max-width: 600px;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &__big-oh {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-21%, -48%) scale(0.01);
        margin: 0 auto;
        pointer-events: none;
        height: 100vh;
        width: 100vw;
        opacity: 0;
        transform-origin: 23% 53%;

        transition: transform 2000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        //transition: opacity ease 500ms;

        &.show {
            opacity: 1;
            transform: translate(-23.75%, -44.75%) scale(1);
            // transform-origin: 25% 50%;
        }

        &.show-centered {
            transform: translate(-50%, -50%) scale(1);
        }

        svg {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: unset;
            height: 100vh;
            width: 100vw;
        }
    }

    &__o-ring {
        position: relative;
        right: -50px;
        top: 50px;
        opacity: 1;
        transition: animate(500);

        @include media('<=480px') {
            right: -56%;
            transform: translateX(-50%) scale(1.4);
        }

        &.hide {
            opacity: 0;
        }

        svg {
            pointer-events: all;
            cursor: pointer;
            height: 100vh;
            width: 90%;
            max-width: 1024px;
            color: c(magenta);
            stroke: c(magenta);
            overflow: visible;
        }

        #Stroke-1 {
            opacity: 0.25;
        }

        #Stroke-3 {
            opacity: 0.4;
        }

        path {
            transition: opacity ease 250ms;

            &.pulse-now-large {
                opacity: 1 !important;
            }
        }

        #Stroke-11 {
            pointer-events: all;
            cursor: pointer;
            //centerpiece
            transition: fill ease 400ms;
            fill: transparent;
            opacity: 1;

            &.flash {
                color: c(magenta);
                fill: currentColor;
            }
        }
    }
    .challenge-prompt__container {
        position: absolute;
        bottom: unset;
        left: 3.5%;
        right: 0;
        margin: 0 auto;
        top: 75%;
        z-index: 15;
        width: 225px;
        transform: translateY(-50%);

        .challenge-prompt__text {
            user-select: none;
            pointer-events: none;
            font-weight: weight(demi);
            font-size: 2.1rem;
        }

        @include media('<=480px') {
            top: unset;
            bottom: 85px;
            transform: 0;
            transform: none;
        }
    }
}
