@font-face {
    font-family: 'Neutraface Text';
    src: url('fonts/NeutrafaceText-Book.eot');
    src: url('fonts/NeutrafaceText-Book.eot?#iefix') format('embedded-opentype'), url('fonts/NeutrafaceText-Book.woff2') format('woff2'),
        url('fonts/NeutrafaceText-Book.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: 'Neutraface Text';
    src: url('fonts/NeutrafaceText-Demi.eot');
    src: url('fonts/NeutrafaceText-Demi.eot?#iefix') format('embedded-opentype'), url('fonts/NeutrafaceText-Demi.woff2') format('woff2'),
        url('fonts/NeutrafaceText-Demi.woff') format('woff');
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
}

@font-face {
    font-family: 'Neutraface Text';
    src: url('fonts/NeutrafaceText-Bold.eot');
    src: url('fonts/NeutrafaceText-Bold.eot?#iefix') format('embedded-opentype'), url('fonts/NeutrafaceText-Bold.woff2') format('woff2'),
        url('fonts/NeutrafaceText-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}
