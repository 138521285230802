.challenge-prompt {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    &__container {
        position: absolute;
        bottom: 50px;
        width: 250px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;

        @include media('<=480px') {
            bottom: 75px;
        }

        svg {
            margin: 0 auto;
            width: 30px;
            height: 84px;
        }
    }

    .challenge-prompt__text {
        margin: 15px auto 0;
        width: 195px;
        pointer-events: none;
        user-select: none;
    }
}
