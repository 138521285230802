.scroll-indicator {
    position: absolute;
    width: 100%;
    max-width: 60px;
    margin: 0 auto;
    transition: animate(400);
    transform: translateY(0);
    transform: translateX(0);
    bottom: 20px;
    right: 50px;
    pointer-events: none;
    opacity: 0;
    z-index: 3;

    @include media('<=480px') {
        bottom: 10px;
        right: 20px;
        width: 25px;
    }

    svg {
        color: c(magenta);
        width: 50px;
        height: 120px;

        path[data-js='arrow-up'] {
            display: none;
        }

        path[data-js='arrow-down'] {
            @include float;
            animation: float 1500ms infinite;
        }
    }

    .scroll-indicator__container {
        opacity: 1;
        @include fadeIn;

        &.loading {
            opacity: 0;
            animation: fadeIn 750ms;
            animation-delay: 0s;
            animation-fill-mode: forwards;
        }
    }

    //states
    &.js-is-active {
        opacity: 1;

        a {
            pointer-events: all;
            cursor: pointer;
        }
    }

    &.smaller {
        max-width: 40px;

        @include media('<480px') {
            max-width: 30px;
            max-height: 95px;
        }
    }

    &.big-left {
        @include media('>768px') {
            left: 0;
            right: 0;
            top: 50%;
            max-width: unset;
            transform: translateY(-50%);
            right: unset;

            .scroll-indicator__container {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                margin: 0 auto;
                max-width: 770px;
            }

            a,
            svg {
                position: relative;
                top: -5px;
                width: 104px;
                height: 250px;
            }
        }
        @include media('<=768px') {
            pointer-events: none;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
        }
    }
}
