.cta-btn {
    padding: 10px 18px;
    color: c(white);
    background: c(magenta);
    border: 2px solid c(magenta);
    font-size: 2rem;
    font-weight: weight(demi);
    line-height: 1.3;
    transition: color ease 400ms;
    transition: background-color ease 400ms;

    @include media('>768px'){
        font-size: 2.4rem;
    }

    &:hover {
        color: c(magenta);
        border: 2px solid c(magenta);
        background: transparent;
    }

    &.style-outline {
        color: c(magenta);
        background: transparent;
        border: 1.4px solid c(magenta);

        &:hover {
            background: c(magenta);
            color: c(white);
        }
    }
}

.text-link{
    border-bottom: 2px solid transparent;
    
    &:hover {
        color: c(magenta);
        border-bottom: 2px solid c(black);
    }
    
}

.text-link-magenta{
    transition: animate(300);
    border-bottom: 2px solid transparent;
    
    &:hover {
        color: c(black);
        border-bottom: 2px solid c(magenta);
    }
    
}