.site-logo {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    max-width: 1170px;
    color: c(magenta);
    transition: animate(500);
    -webkit-transform-style: preserve-3d;

    &__container {
        position: absolute;
        width: 100%;
        max-width: 150px;
        margin: 0 auto;
        transition: animate(500);
        -webkit-transform-style: preserve-3d;
        top: 0;
        transform: translateY(0);
        left: 50%;
        transform: translateX(-50%);

        @include media('<767px') {
            top: 15px;
        }
    }

    a {
        pointer-events: all;
    }

    svg {
    }

    /*———— -states- ————*/
    &.js-is-active {
        z-index: -1;
        width: 100%;

        @include media('<=480px') {
            width: 90%;
        }

        a {
            display: block;
            width: 100%;
        }

        svg {
            height: 295px;
            min-height: 295px;
            width: 100%;
        }

        .site-logo__container {
            transition: all 800ms ease-in-out;
            -webkit-transform-style: preserve-3d;
            margin: 0;
            max-width: 625px;
            top: 50%;
            left: -30px;
            transform: translateX(0);
            transform: translateY(-50%);

            @include fadeIn;

            &.loading {
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                animation: fadeIn 750ms;
                animation-delay: 0s;
                animation-fill-mode: forwards;
            }
        }
    }

    &.is-nav {
        z-index: 20;
        @include media('<=768px') {
            .site-logo__container {
                transform: none;
                left: unset;
                right: 24px;
                top: -30px;
                max-width: 100px;
            }
        }
        &.fly-away {
            transform: translateY(-100%);
        }
    }

    &.is-thank-you {
        @include media('<=480px') {
            display: none;
        }
    }

    &.big-OH {
        opacity: 0;
        color: c(pink-tint);
        max-width: 1336px;
        transition: all 400ms ease-in-out;
        -webkit-transform-style: preserve-3d;

        @include media('<=480px') {
            width: 90%;
            transition: opacity 400ms ease-in-out;
            -webkit-transform-style: preserve-3d;
        }

        a {
            display: block;
            width: 100%;
        }

        .site-logo__container {
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: unset;

            @include media('<=480px') {
                top: 50%;
                transform: translateY(-50%);
                //kill the flickering transform on Mobile
                transition: opacity 800ms ease-in-out;
                -webkit-transform-style: preserve-3d;
            }

            svg {
                height: 100vh;
                width: 100%;
            }
        }

        &.js-is-active {
            opacity: 1;
        }

        //climax-animation
        &.climax-OH {
            width: 100%;
            transition: all 800ms ease-in-out;
            z-index: 100;
            pointer-events: none;
            color: c(magenta);

            @include media('<=768px') {
                width: 90%;
            }

            &.opacity-override {
                opacity: 0 !important;
            }

            &.centered {
                .cls-2 {
                    position: relative;
                }

                .OH-O {
                    transform: translateX(25%);
                }
            }

            &.tiny {
                transform: scale(0.01);
            }

            &.gigantic {
                transform: scale(3);

                @include media('<=768px') {
                    transform: scale(5);
                }
            }

            .OH-O {
                transition: all 800ms ease-in-out;
            }

            .OH-H {
                transition: animate(750);
                opacity: 0;

                &.js-is-active {
                    opacity: 1;
                }
            }
        }
    }
}
