.conversion-form {
    @include checkbox;
    position: relative;
    top: 50px;

    &__container {
        width: 100%;
        max-width: 1170px;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        @include media('>=768px') {
            width: 90%;
            justify-content: space-between;
        }
    }

    .product-image {
        opacity: 0;
        transition: animate(500);
        user-select: none;
        @include media('<=480px') {
            display: none;
        }

        flex-basis: 25%;

        @include media('>768px') {
            flex-basis: 50%;
        }

        img {
            position: relative;
            left: 150px;
            height: 400px;
            width: auto;
            opacity: 1;
            transition: animate(600);
            transform: translateX(-35px);
        }
    }

    .conversion-form-wrapper {
        opacity: 0;
        transition: animate(500);
        flex-basis: 75%;
        display: block;
        max-width: 490px;
        padding-right: 20px;

        @include media('<=480px') {
            position: relative;
            flex-basis: 80%;
            padding-right: 0px;
        }

        @include media('>768px') {
            flex-basis: 50%;
            padding-right: 0;
        }
    }

    &__header-group {
        transition: animate(500);
        display: inline-block;
        text-align: left;
        overflow: hidden;
        opacity: 1;
    }

    &__header {
        font-size: 5rem;
        display: inline-block;
        margin-bottom: 12px;
        user-select: none;

        @include media('<=390px') {
            font-size: 4.5rem;
        }
    }

    &__input {
        display: flex;
        margin-top: 20px;
        flex-flow: row nowrap;
        color: c(magenta);

        @include media('<=480px') {
            transition: animate(500);

            &.stay-visible{
                position: absolute;
                top: 0;
            }
        }
    }

    &__email-consent {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        &.js-is-invalid {
            color: c(magenta);
        }

        label {
            width: 40px;
        }

        p {
            flex-basis: 85%;
            font-size: 1.2rem;

            @include media('>768px') {
                font-size: 2.4rem;
            }

            &.small {
                font-size: 1.6rem;
                max-width: 375px;
            }
        }
    }

    [data-js='conversion-input'] {
        width: 100%;
        font-size: 1.3rem;
        font-weight: weight(bold);
        line-height: 1;
        vertical-align: baseline;
        padding: 20px 20px 18px;

        @include media('<=480px') {
            font-size: 1.7rem;
        }

        @include placeholder(true) {
            color: c(magenta);
            opacity: 0.8;
        }

        @include media('>768px') {
            font-size: 2.4rem;
        }
    }

    [data-js='conversion-submit'] {
        padding-left: 22px;
        padding-right: 22px;
    }

    .tiny-text {
        position: absolute;
        bottom: 0;
        right: -5px;

        @include media('<=480px') {
            right: unset;
            left: 50%;
            transform: translate(-50%);
        }
    }
}
