.site-nav {
    position: absolute;
    z-index: 100;
    top: 40px;
    left: 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 125px;
    opacity: 1;
    transition: animate(500);

    @include media('<=480px') {
        width: 105px;
        left: 25px;
        top: 25px;

        &.reading-mode {
            opacity: 0;
        }
    }

    a {
        font-size: 2.2rem;
        font-weight: weight(bold);
        transition: color ease 300ms;
        line-height: 1;

        &:hover {
            color: c(magenta);
            border-bottom: 2px solid c(black);
        }
    }

    &.fly-away {
        transform: translateY(-250%);
    }
}
