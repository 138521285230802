.cursor-feedback {
    position: absolute;
    pointer-events: none;
    top: 270px;
    left: 50%;
    transform: translateX(-50%);
    overflow: visible;

    @include media('<=768px') {
        top: 150px;
    }

    p {
        text-align: center;
        color: c(magenta);
        display: none;
        opacity: 0;
        font-weight: weight(bold);
        font-size: 1.6rem;
        position: relative;
        bottom: 30px;

        &.cloned {
            display: block;
        }

        &.size-2 {
            font-size: 1.8rem;
        }
        &.size-3 {
            font-size: 2rem;
        }
        &.size-4 {
            font-size: 2.6rem;
        }
        &.size-5 {
            font-size: 3.2rem;
        }
    }

    [data-js='nickname-feedback'] {
        text-transform: capitalize;
    }
}
